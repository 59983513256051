<template>
  <Breadcrumb :urls="urls"  :translationloaded="translationloaded" :translate="translate"></Breadcrumb>
  <WhatsAppButton></WhatsAppButton>
  <div class="account-info-sec" v-if="translationloaded==true">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <div id="show-hidden-menu" class="dashBoarsBtnMain" @click="toggledashboard()">
            <i class="fa fa-th-large" aria-hidden="true"></i>{{translate('dashboard')}}
          </div>
          <div  id ="vdasboard" class="hidden-menu" style="display: none;">
           <SideBar
              :translationloaded="translationloaded"
              :translate="translate"
            ></SideBar>
          </div>
          <div class="mainSideDiplay">
            <SideBar
              :translationloaded="translationloaded"
              :translate="translate"
            ></SideBar>
          </div>
        </div>
       <div class="col-lg-9">
                     <div class="row">
                <div class="col-md-6">
                    <div class="profile_block">
                      <a href="javascript:void(0)" class="edit-img" @click="editProfile()">
                        {{translate('edit')}}
                      </a>
                      <label><b>{{translate('fullname')}}</b></label>
                      <p>{{ getCustomerInfo.custName }}</p>
                      <label><b>{{translate('emailId')}}</b></label>
                      <p>{{ getCustomerInfo.emailId }}</p>
                      <label><b>{{translate('mobileNumber')}}</b></label>
                      <p>{{ getCustomerInfo.phoneNo }}</p>
                    
                    </div>
                </div>
                 <div class="col-md-6">
                       <div class="profile_edit_form" v-if="edit_form_open_status">
              <div class="dlte-icon">
                <img
                  src="/assets/img/deleteicon.png"
                  @click="hideProfileForm"
                />
              </div>

              <input
                type="text"
                name="first_name"
                :class="{ error_border: first_name_error }"
                v-model="first_name"
                placeholder="first name"
              />
              <input
                type="text"
                name="email"
                :class="{ error_border: email_error }"
                v-model="email"
                placeholder="Email"
                @change="OnchangeUsername()"
              />
               <input
                type="text"
                name="phone"
                :class="{ error_border: phone_error }"
                v-model="phone"
                placeholder="phone"
                @change="OnchangeUsername()"
              />
          
             
              <div class="savebtn">
                <button type="submit" @click="updateProfiles">
                  {{translate('updateProfile')}}
                </button>
              </div>
            </div>
                 </div>
                      </div>
                     
              </div>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "../components/Breadcrumb";
import SideBar from "../components/my_account/SideBar";
import WhatsAppButton from "@/components/WhatsAppButton";
import { mapGetters, mapActions } from "vuex";
import helper from "@/helper/helper";
import Config from  '../config';
import axios from "axios";
import moment from 'moment';


export default {
  name: "MyProfile",
  components: {
    Breadcrumb,
    SideBar,
    WhatsAppButton
  },
  props:[
    'translationloaded',
    'translate'
  ],
  data() {
    return {
   
      urls: [
        {
          path: "/",
          name: "Home",
        },

        {
          path: "/my-profile",
          name: "MyProfile",
        },
      ],
      customer_previous_inserted_email:'',
      customer_previous_inserted_phone:'',
      edit_form_open_status: false,
      first_name: "",
      email: "",
      phone: "",
      phone_error: "",
      phone_error_msg: "",
      email_error: "",
      email_error_msg: "",
      first_name_error: "",
      first_name_error_msg: "",
      errorStatus:false,
      userChkError:'',
      currency:'',
      walletTransaction:[],
      transactionOpen:false
    };
  },
  methods: {
        toggledashboard()
    {
       var x = document.getElementById("vdasboard");
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
    },

    ...mapActions(["updateProfile"]),
    transactionOpenBox(){
      this.transactionOpen =!this.transactionOpen;
    },
    editProfile() {
      this.edit_form_open_status = true;
      this.first_name = this.getCustomerInfo.custName;
      this.email = this.getCustomerInfo.emailId;
      this.phone = this.getCustomerInfo.phoneNo;
      this.customer_previous_inserted_email = this.getCustomerInfo.emailId;
      this.customer_previous_inserted_phone = this.getCustomerInfo.phoneNo;
    },
    updateProfiles() {
      let errStatus = this.validateForm();
      if (errStatus == false && this.errorStatus==false) {
        let $this = this;
        let payload = {
          custId: localStorage.getItem("custId"),
          custName: this.first_name,
          emailId: this.email,
          phoneNo: this.phone,
        };
        $this
          .updateProfile(payload)
          .then(function(response) {
            $this.$toast.success(`${response.data.Message}`);
            $this.hideProfileForm();
             helper.backtoTop();
          })
          .catch(function(error) {
            $this.$toast.error(`${error.response.data.Message}`);
          });
      }else{
            if(this.userChkError !=''){
                 this.$toast.error(`${this.userChkError}`);
            }
      }
    },
    hideProfileForm() {
      this.edit_form_open_status = false;
    },
       OnchangeUsername(){
            // let username ='';
            // if(type=='email'){
            //     username = this.email;
            // }else if(type =='phone'){
            //       username =this.phone
            // }
            let  $this =this;
             $this.userChkError='';
             $this.errorStatus = false;
             if(($this.customer_previous_inserted_email != $this.email)||($this.customer_previous_inserted_phone != $this.phone)){
              let checkingArr =['email','phone'];
                  checkingArr.forEach(function(item){
                      if(item=='email' && $this.customer_previous_inserted_email != $this.email){
                                axios.get(`${Config.BASE_URL}Customer/UserExistCheck?UserName=${$this.email}`,Config.ADMIN_API_HEADER).then(function(response){
                                  // console.log(response);
                                  if(response.data.Data == true){
                                      $this.$toast.error(`${response.data.Message}`);
                                      $this.errorStatus = true;
                                      $this.userChkError=response.data.Message;

                                  }
                              });

                      }

                      if(item=='phone' && $this.customer_previous_inserted_phone != $this.phone){
                                axios.get(`${Config.BASE_URL}Customer/UserExistCheck?UserName=${$this.phone}`,Config.ADMIN_API_HEADER).then(function(response){
                                  // console.log(response);
                                  if(response.data.Data == true){
                                      $this.$toast.error(`${response.data.Message}`);
                                      $this.errorStatus = true;
                                      $this.userChkError=response.data.Message;

                                  }
                              });

                      }

                  });
             }
          
        },
    validateForm() {
      let errorStatus = false;
      if (this.first_name == "") {
        this.first_name_error = true;
        errorStatus = true;
      } else {
        this.first_name_error_msg = "";
        this.first_name_error = false;
      }

      if (this.email == "") {
        this.email_error = true;
        errorStatus = true;
      } else {
        this.email_error_msg = "";
        this.email_error = false;
      }
      if (this.phone == "") {
        this.phone_error = true;
        errorStatus = true;
      } else {
        this.phone_error_msg = "";
        this.phone_error = false;
      }

      return errorStatus;
    },
    getWalletTransaction(){
      let $this=this;
      axios.get(`${Config.BASE_URL}GetWallet?custId=${localStorage.getItem("custId")}`).then(function(response){
          $this.walletTransaction=response.data.Data;
      });
    },
     formatedDate(date){
           return moment(date).format('MMMM D, YYYY');
      },
  },
  computed: {
    ...mapGetters(["getCustomerInfo"]),
  },
  mounted() {   
    helper.backtoTop();
    this.getWalletTransaction();
    this.currency = Config.CURRECNCY;
  },
};


</script>


<style scoped>
.profile_block {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}
.edit-img {
  color: red !important;
  padding: 10px;
  position: absolute;
  top: 0;
  right: 17px;
}

input {
  width: 100%;
  box-shadow: inherit;
  height: 45px;
  padding-left: 15px;
  margin-bottom: 15px;
  border: 1px solid #b3b3b396;
  font-size: 15px;
  border-radius: 0;
}
.error_border {
  border: 1px solid red !important;
}

.profile_edit_form {
  position: relative;
}

.profile_edit_form .dlte-icon {
  top: -5px !important;
  position: absolute !important;
  right: 27px !important;
}
.walletAmountBox{
 min-height: 100px;
    /* background: #ddd; */
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-top:10px;

}
.walletAmountBox p{
    margin-top: 3%;
    padding: 19px;
    font-size: 1.8rem;
}
.w-amount{
  font-size: 2rem;
  font-weight: bold;
}
.txn-btn{
   
    background: #ee2b2a;
    padding: 9px;
    border-radius: 10px;
    position: absolute;   
    top: 43px;
    right: 25px;
    color: #fff!important
}
.transactionHistory{
   border-radius: 10px;
   border:1px solid #ddd;
   padding-left: 10px;
   padding-right: 10px;
   padding-bottom:10px;
}
.transactionHistory h4{
  margin-top:20px;
}
.transactionList{
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #ddd;
    font-size:12px;
}
.thead{
  padding: 5px;
  display: inline-block;
}
</style>
